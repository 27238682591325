@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
}
a {
  @apply cursor-pointer;
}

.card {
  @apply bg-white px-4 py-4 rounded-xl;
}
.card,
footer {
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}
.custom-shadow {
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}
.btn-primary {
  @apply inline-flex justify-center px-4 py-2 text-xs font-normal text-white bg-primary border border-transparent rounded-md;
}
.btn-secondary {
  @apply inline-flex justify-center px-4 py-2 text-xs font-normal text-primary bg-white border border-primary rounded-md;
}
.btn-disabled {
  @apply text-white bg-lightGray opacity-50 cursor-not-allowed;
}
.search-input {
  @apply border border-lightGray  transition h-10 px-5 pl-12 rounded-md  w-full text-sm text-GeminiGray focus:outline-none   focus:border-primary focus:ring-opacity-5;
}
.card-title {
  @apply text-darkBlack text-sm font-medium;
}
.default-input {
  @apply border border-lightGray  transition h-10 px-5  pl-4 pr-4 rounded-md  w-full md:text-base text-sm text-darkBlack focus:outline-none   focus:border-primary  font-normal bg-transparent;
}
.default-input::placeholder {
  @apply text-GeminiGray;
}
.is-invalid,
.is-invalid-select {
  @apply border-red-600;
}
.invalid-feedback {
  @apply border-red-600 z-40;
}
.footer {
  left: 0;
  width: calc(100vw);
}
.transform-50-50 {
  transform: translate(0, -50%);
}
.transform-center {
  transform: translate(-50%, -50%);
}
.summary-screen {
  @apply bg-white fixed md:w-2/5 w-full h-full top-0 right-0 z-50 overflow-auto rounded-none shadow-md;
}
.top-right {
  transition: transform 0.4s ease-in-out;
  animation: toast-in-right 0.4s;
}
@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
#splash-screen {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 60px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #2f3033 transparent #2f3033 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.autocomplete-wrapper input {
  @apply border border-lightGray  transition h-10 px-5  pl-4 pr-4 rounded-md  w-full md:text-base text-sm text-darkBlack focus:outline-none   focus:border-primary  font-normal bg-transparent;
}
.autocomplete-wrapper input::placeholder {
  @apply text-GeminiGray;
}
.autocomplete-wrapper input + div {
  margin-top: 2px;
  background-color: #fff !important;
  position: absolute !important;
  top: 41px !important;
  left: 0 !important;
  max-height: unset !important;
}
.autocomplete-wrapper div {
  @apply z-50;
}
.grid-8 {
  grid-template-columns: auto auto auto auto auto auto auto auto;
}
.grid-2 {
  grid-template-columns: auto auto;
}
.flex-2 {
  flex: 2;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.tooltip {
  @apply invisible absolute;
}

.has-tooltip:hover .tooltip {
  @apply visible z-50;
}
.dim-grid {
  grid-template-columns: 60% 35%;
}
.dimen-list .grid:nth-child(even) {
  @apply bg-foggySummer rounded-md;
}
.css-14el2xx-placeholder {
  @apply md:text-base text-sm text-GeminiGray focus:outline-none  focus:border-primary  font-normal bg-transparent;
}
.css-qc6sy-singleValue {
  @apply md:text-base text-sm font-normal text-GeminiGray;
}
.product-listing {
  min-height: calc(100vh - 200px);
}
.upload-shipment {
  min-height: calc(100vh - 164px);
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
  @apply bg-[#c6c7c4] rounded-[10px];
}
.cartActive {
  border-top-color: #b2b2b2 !important;
}
.cardview {
  height: 228px !important;
}
.transion-accord {
  transition: all 1s;
}

.rotate-icons {
  transform: rotate(180deg);
}

.submenu::before {
  content: "";
  position: absolute;
  top: 16%;
  left: 13px;
  width: 1px;
  height: 80%;
  background-color: #fff;
  transform: translate(-50%, 0);
}
.borter-b-color {
  border-bottom-color: #b2b2b2 !important;
}
.transion-listview {
  transition: all 0.5s;
}
.submenu-animation {
  transition: all 0.6s;
}
.ring-input input:focus {
  @apply ring-0 ring-transparent;
}
.ring-input .react-select {
  @apply mt-1;
}
.reactPagination li a {
  @apply bg-white  text-OlderGray relative inline-flex items-center px-2 py-2  text-sm font-medium   rounded-md;
}

.reactPagination :first-child > a,
.reactPagination :last-child > a {
  @apply py-3.5;
}

.reactPagination .flex {
  @apply space-x-2;
}
.reactPagination .selected a {
  @apply text-primary border border-primary px-3 py-2;
}
.content-css br {
  @apply hidden;
}
.content-css span:last-child a {
  @apply block mt-2;
}
.empty-data {
  min-height: calc(100vh - 300px);
}
.pdf-viewer .react-pdf__Page__svg {
  @apply h-full w-full;
}
.table-header {
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
  top: 0;
}
.transform-267 {
  transform: rotate(271deg);
}
.transform-90 {
  transform: rotate(90deg);
}
.is-invalid {
  @apply border border-red-600;
}
.shipmentChooseDate input:focus {
  @apply ring-0 ring-transparent;
}
.shipmentChooseDate input {
  @apply invisible md:w-28 w-20;
}
.shipmentChooseDate {
  @apply absolute lg:-top-4 top-3 left-0;
}
.apexcharts-menu-item.exportSVG,
.apexcharts-datalabel-label {
  @apply hidden;
}

.apexcharts-menu-icon {
  margin-top: 5px !important;
}
.upload-input {
  grid-template-columns: 48% auto 48%;
}

.apexcharts-datalabel-value {
  @apply font-bold;
}
.empty-card {
  min-height: calc(100% - 200px);
}
.react-select [class$="ValueContainer"] {
  padding: 3px 14px;
}
.react-pdf__Page__svg {
  @apply mx-auto;
}
.react-pdf__Document.pdf-viewer {
  @apply py-3;
}
.autocomplete-search input {
  @apply pl-10;
}
.phone-input input {
  @apply w-full;
}
.phone-input .react-tel-input .flag-dropdown {
  border-radius: 6px 0 0 6px;
  margin: 1px;
  border: none;
}
.phone-input .react-tel-input input {
  line-height: 26px;
}
.phone-input .react-tel-input .selected-flag:hover,
.phone-input .react-tel-input .selected-flag:focus {
  @apply bg-[#f5f5f5];
}
.colspan-2 {
  grid-column: 1 / span 3;
}
iframe {
  pointer-events: none;
}
.notification-head {
  box-shadow: 0px 1px 3px -1px #00000038;
}
.date-picker-close {
  padding-top: 20% !important;
}
.react-datepicker__close-icon {
  position: relative !important;
  right: -5% !important;
}
.notification-card {
  min-height: calc(100vh - 125px);
}
.notification-scroll-height {
  height: calc(100vh - 150px) !important;
}
.notification-datepicker .react-datepicker__close-icon {
  top: -28px !important;
  right: -25% !important;
}
/* For hide edge-browser extra icons... */
::-ms-reveal {
  display: none;
}

@screen sm {
  .dim-grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ::-webkit-scrollbar {
    @apply w-1.5 h-2.5;
  }
  .notification-datepicker .react-datepicker__close-icon {
    top: -25px !important;
    right: -25% !important;
  }
}
@screen md {
  .card {
    @apply px-6 py-6;
  }
  .card-title {
    @apply text-lg;
  }
  .btn-primary {
    @apply text-sm;
  }
  .btn-secondary {
    @apply text-sm;
  }
  .footer {
    left: 240px;
    width: calc(100vw - 240px);
  }
  .notification-datepicker .react-datepicker__close-icon {
    top: -30px !important;
    right: -4% !important;
  }
}
@media screen(lg) {
  .shipment-lable .summary-stickey {
    height: calc(100vh - 205px);
  }
  .summary-stickey {
    height: calc(100vh - 275px);
    overflow: auto;
  }
  .notification-datepicker .react-datepicker__close-icon {
    top: 0px !important;
    right: -4% !important;
  }
}
@media screen(1xl) {
  .unit-btns {
    width: 100%;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
